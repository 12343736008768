import React from 'react';
import s from './header';
import { Link } from 'react-router-dom';
import Navitem from '../Navitem';

const Header = () => {
  return (
    <header className={s.header}>
      <section className="container">
        <div>
          <div className="row middle-xs">
            <div
              className="col-xs-3">
              <div className="box">
                <Link to="/" className={s.logo}>
                  JAMstack
                </Link>
              </div>
            </div>
            <div
              className="col-xs-7">
              <div className={`box ${s.navbox}`}>
                <Navitem url="/portfolio">Portfolio</Navitem>
                <Navitem url="/news">News</Navitem>
                <Navitem url="/about">About Me</Navitem>
              </div>
            </div>
            <div
              className="col-xs-2">
              <div className="box">
                <div className={s.contact_link}>
                  <Navitem url="/contact" >
                    Say hello
                  </Navitem>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};
export default Header;
