import React from 'react';
import s from './footer';
import { Link } from 'react-router-dom';
const FooterLink = () => {
  return (
    <>
      <h2 className={s.link_title}>Lorem ipsum</h2>
      <Link className={s.link} to="/">
        test link name
      </Link>
      <Link className={s.link} to="/">
        test link name
      </Link>
      <Link className={s.link} to="/">
        test link name
      </Link>
      
    </>
  );
};
const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className="container">
        <section className={`${s.lonkbox} row`}>
          <div
            className="col-xs-6
                col-sm-6
                col-md-3">
            <FooterLink></FooterLink>
          </div>
          <div
            className="col-xs-6
                col-sm-6
                col-md-3">
            <FooterLink></FooterLink>
          </div>
          <div
            className="col-xs-6
                col-sm-6
                col-md-3">
            <FooterLink></FooterLink>
          </div>
          <div
            className="col-xs-6
                col-sm-6
                col-md-3">
            <FooterLink></FooterLink>
          </div>
        </section>
      </div>
      <div className={s.bottombar}>
        <div className="container">
          <div className="row middle-xs">
            <div
              className="col-xs-4
                col-sm-4">
              © 2020 All rights reserved.
            </div>
            <div
              className={`col-xs-4
                col-sm-4 ${s.logo_bx}`}>
              {/* <img src={logo} className={s.footerlogo} /> */}
            </div>
            <div
              className={`col-xs-4
                col-sm-4 ${s.terms_link}`}>
              <Link to="/">Terms & Conditions</Link> |{' '}
              <Link to="/">Privacy policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
