import React from 'react';
import s from './navitem';
import { NavLink } from 'react-router-dom';
const NavItem = ({ children, url }) => {
  return (
    <NavLink
      exact={true}
      to={url}
      className={s.link}
      activeClassName={s.selected}>
      {children}
    </NavLink>
  );
};
export default NavItem;
