import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Preloader from '@/components/Preloader';

import routes from '@/routes';
const routeComponents = routes?.map(({ component, ...rest }) => ({
  ...rest,
  LazyComponent: lazy(component),
}));

class App extends Component {
  render() {
    return (
      <div className="app">
        <Router>
          <Header />
          <main>
            <Suspense fallback={<Preloader/>}>
              <Switch>
                {routeComponents?.map(({ LazyComponent, ...rest }, i) => {
                  return (
                    <Route key={i} {...rest} exact>
                      {<LazyComponent />}
                    </Route>
                  );
                })}
              </Switch>
            </Suspense>
          </main>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
