const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ './pages/Home'),
    exact: true,
  },
  {
    path: '/portfolio',
    component: () =>
      import(/* webpackChunkName: "portfolio" */ './pages/Portfolio'),
    exact: true,
  },
  {
    path: '/news',
    component: () =>
      import(/* webpackChunkName: "news" */ './pages/Services'),
    exact: true,
  },
  {
    path: '/about',
    component: () =>
      import(/* webpackChunkName: "about" */ './pages/About'),
    exact: true,
  },
  {
    path: '/contact',
    component: () =>
      import(/* webpackChunkName: "about" */ './pages/Contact'),
    exact: true,
  },
];
export default routes;
