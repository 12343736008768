import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import '@/assets/styles/flexboxgrid';
import '@/assets/styles/common';
import '@/assets/styles/iconfonts';
import App from './App';
ReactDOM.render(React.createElement(App), document.getElementById('approot'));

// Check if hot reloading is enable. If it is, changes won't reload the page.
// This is related to webpack-dev-server and works on development only.
if (module.hot) {
  module.hot.accept();
}
